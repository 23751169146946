import { PDFXClass } from "../../static/08-borgona"
import {
    Astro,
    Astro1,
    AstroPDF,
    Atlantis,
    Atlantis01,
    AtlantisPDF,
    BotellaGrandeur,
    BotellaGrandeurPDF,
    BotellaGrandeur1,
    Kaleido,
    Kaleido1,
    KaleidoPDF,
    Forte,
    Forte1,
    FortePDF,
    HONOR,
    HONOR1,
    HONORPDF,
    LONDON1,
    LONDONPDF,
    LONDON
} from "../images/productos/area-premium/index"
import { XClass, XClassModal } from "../images/productos/botella_vino/borgona"




export const Premium = [{
    size: 0,
    name: "",
    src: Astro1,
    srcModal: Astro,
    pdfDownload: AstroPDF,

}, {
    srcModal: Atlantis,
    pdfDownload: AtlantisPDF,
    name: "",
    src: Atlantis01,

}, {
    srcModal: BotellaGrandeur,
    pdfDownload: BotellaGrandeurPDF,
    size: 0,
    name: "",
    src: BotellaGrandeur1,

}, {
    srcModal: Kaleido1,
    pdfDownload: KaleidoPDF,
    size: 0,
    name: "",
    src: Kaleido,

}, {
    srcModal: Forte1,
    pdfDownload: FortePDF,
    size: 0,
    name: "",
    src: Forte
}, {
    srcModal: HONOR,
    pdfDownload: HONORPDF,
    size: 0,
    name: "",
    src: HONOR1,

}, {
    srcModal: LONDON,
    pdfDownload: LONDONPDF,
    size: 0,
    name: "",
    src: LONDON1,

}, {
    srcModal: XClassModal,
    pdfDownload: PDFXClass,
    size: 0,
    name: "",
    src: XClass,

}]