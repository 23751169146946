import React from "react";
import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";

import SEO from "../../components/SEO/Seo";
import { Premium } from "../../data/area-premium";

const ProcesosEspeciales = () => {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location =
  //     "http://www.blueskysa.com.ar/movile/20-pintado-impresas.html";
  // }
  return (
    <>
      <SEO
        title="Blue Sky SA -  Area Premium"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  ,Blue Sky SA , Procesos adicionales , Area Premium. Arenado , Pintado de botellas. Colores varios. Botellas coloreadas , Argentina."
        description=" Procesos adicionales - Area Premium. Arenado - Pintado de botellas. Colores varios. Botellas coloreadas - Argentina."
      />
      <Products subtitle="Area Premium">
        <ProductCard data={Premium} />
      </Products>
    </>
  );
};

export default ProcesosEspeciales;
